import './App.css';
import SignUp from './components/SignUp';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadHubSpotScript } from './utils/utils';

function App() {
  ReactGA.initialize('G-8QERXNZ66X')
  useEffect(() => {
    loadHubSpotScript();
  }
  , []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignUp />} />
      </Routes>
    </Router>
  );
}

export default App;
