import axios from 'axios'
import config from '../config'
import MockAdapter from 'axios-mock-adapter'

const api = axios.create({
  baseURL: config.apiUrl,
  // Always add credentials in HTTP-only cookie to all requests.
  withCredentials: true,
  xsrfCookieName: 'bogus-cookie-name' // workaround for https://security.snyk.io/vuln/SNYK-JS-AXIOS-6032459
})

if(config.apiUrl === 'mock-api') {
  const mock = new MockAdapter(api)

  mock.onPost('/verify-email').reply(201, {})
  mock.onPost('/verify-code').reply(200, {"signupCode": "test-code"})
  mock.onPost('/create-account').reply(201, {})
  mock.onPost('/resend-code').reply(201, {})
}

// add api interceptor to read csrf_access_token from cookies and add it to X-CSRF-TOKEN header
api.interceptors.request.use(
  (request) => {
    if (request.method !== 'post' && request.method !== 'put' && request.method !== 'patch' && request.method !== 'delete') {
      return request
    }
    if (request.baseURL !== config.apiUrl) {
      return request
    }
    // Handle empty cookie
    if (!document.cookie) {
      return request
    }

    // Handle single cookie row
    if (!document.cookie.includes('; ')) {
      const csrfToken = document.cookie.split('=')[1]
      request.headers['X-CSRF-TOKEN'] = csrfToken
      return request
    }
    const csrfTokenCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('csrf_access_token'))
    if (csrfTokenCookie) {
      const csrfToken = csrfTokenCookie.split('=')[1]
      request.headers['X-CSRF-TOKEN'] = csrfToken
    }

    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api