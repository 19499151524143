import React from 'react'

import {
  Button, Stack, Box
} from '@mui/material'
import api from '../api/api'

export const SSOButtons = () => {
  const handleSSOClick = async (provider) => {
    const response = await api.post('/sso/' + provider)
    if (response.status === 200) {
      window.location.href = response.data.redirect
      if (window.location.href !== response.data.redirect) {
        window.location.href = response.data.redirect
      }
    }
  }
  return (
    <Stack
        spacing={1}
        marginTop={2}
    >
        <Button
            size="small"
            variant="outlined"
            fullWidth
            onClick={() => handleSSOClick('azure')}
            data-testid="microsoftSignInButton"
            disableFocusRipple={true}
            color='inherit'
            sx={{
              borderRadius: '12px',
              height: '42px',
              borderColor: '#D2D6DB',
              textTransform: 'none'
            }}
        >
            <Box
            component="img"
            sx={{
              mr: 1,
              maxWidth: 21
            }}
            src={'/assets/logos/sso/microsoft.svg'}
            alt={'Continue with Microsoft'}
            />
            {'Continue with Microsoft'}
        </Button>
        <Button
            size="small"
            variant="outlined"
            fullWidth
            onClick={() => handleSSOClick('google')}
            data-testid="googleSignInButton"
            disableFocusRipple={true}
            color='inherit'
            sx={{
              borderRadius: '12px',
              height: '42px',
              borderColor: '#D2D6DB',
              textTransform: 'none'
            }}
        >
            <Box
            component="img"
            sx={{
              mr: 1,
            }}
            src={'/assets/logos/sso/google.svg'}
            alt={'Continue with Google'}            
            />
            {'Continue with Google'}
        </Button>        
    </Stack>
  )
}

SSOButtons.propTypes = {
}
