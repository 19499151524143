import React, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Link, Box, Typography, Stack, Checkbox, MenuItem, FormControl, FormHelperText, InputAdornment, FormControlLabel, CircularProgress } from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { getSupportedRegions } from '../../utils/utils';
import { getHubspotUTK, clearHubspotUTK, getUserRegion } from '../../utils/utils';

import api from '../../api/api';
export const StepThree = ({nextStepCallback, email, signupCode, fullName, initializedThroughSSO, provider}) => {
  const passwordRules = "Password must be at least 8 characters, contain at least one number, one uppercase letter, one lowercase letter, and one special character.";
  const shortnameRules = "Your organization's part of the Keystrike URL must be no more than 63 characters, contain only lowercase alphanumeric characters and can include hyphens. It must start and end with an alphanumeric character.";
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [trackingCode, setTrackingCode] = useState(null);
  const [defaultRegion, setDefaultRegion] = useState("");

  const getDefaultShortName = () => {
    if (email) {
      const domain = email.split('@')[1];
      const allButLastDomainLabels = domain.split('.').slice(0, -1);
      const allButLastDomainLabelsAsString = allButLastDomainLabels.join('.');
      return allButLastDomainLabelsAsString.replace(/[^a-zA-Z0-9-]/g, '-').toLowerCase();
    }
    return '';
  }

  const splitFullName = (value) => {
    const names = value.trim().split(' ');

    if (names.length === 1) {
      return {
        firstName: names[0],
        lastName: ''
      }
    }

    const firstName = names.slice(0, -1).join(' ');
    const lastName = names[names.length - 1];

    return {
      firstName,
      lastName
    }
  }

  const getRegionDisplayName = (region) => {
    switch (region) {
      case 'EU':
        return 'Europe (EU)';
      case 'US':
        return 'United States (US)';
      default:
        return region;
    }
  }

  useEffect(() => {
    setTrackingCode(getHubspotUTK());
    clearHubspotUTK();
    getUserRegion().then((region) => {
      setDefaultRegion(region);
    });
  }, []);

  const forceValidShortName = (event) => {
    event.target.value = event.target.value.toLowerCase();
    event.target.value = event.target.value.replace(/[^a-zA-Z0-9-]/g, '');
    //handle cannot start or end with a hyphen
    if (event.target.value.startsWith('-')) {
      event.target.value = event.target.value.substring(1);
    }
    //handle cannot have consecutive hyphens
    event.target.value = event.target.value.replace(/-+/g, '-');
    formik.handleChange(event);
  }

  const getValidationSchema = () => {
    if (initializedThroughSSO) {
      return Yup.object({
        firstName: Yup
          .string()
          .max(32)
          .required('First Name is required'),
        lastName: Yup
          .string()
          .max(32)
          .required('Last Name is required'),
        email: Yup
          .string()
          .email('Must be a valid email')
          .max(254)
          .required('Email is required'),
        orgName: Yup
          .string()
          .max(64)
          .required('Organization name is required'),
        orgShortName: Yup
          .string()
          .max(63)
          .required('Keystrike URL is required')
      })
    } else {
      return Yup.object({
        firstName: Yup
          .string()
          .max(32)
          .required('First Name is required'),
        lastName: Yup
          .string()
          .max(32)
          .required('Last Name is required'),
        password: Yup
          .string()
          .min(8, passwordRules)
          .max(256)
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/, passwordRules)
          .required('Password is required'),
        confirmPassword: Yup
          .string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is required'),
        email: Yup
          .string()
          .email('Must be a valid email')
          .max(254)
          .required('Email is required'),
        orgName: Yup
          .string()
          .max(64)
          .required('Organization name is required'),
        orgShortName: Yup
          .string()
          .max(63)
          .matches(/^[a-z0-9]([a-z0-9\\-]*[a-z0-9])?$/, shortnameRules)
          .required('Keystrike URL is required')
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: splitFullName(fullName).firstName,
      lastName: splitFullName(fullName).lastName,
      email: email,
      password: '',
      confirmPassword: '',
      orgName: '',
      orgShortName: getDefaultShortName(),
      region: defaultRegion,
      termsAccepted: false,
      signupCode: signupCode,  
      submit: ''
    },
    validationSchema: getValidationSchema(),
    onSubmit: async (values, helpers) => {
      if (!values.termsAccepted) {
        helpers.setErrors({ submit: 'Please accept the terms and conditions' });
        return;
      }
      try {
        setLoading(true);
        let requestBody = {
          email: values.email,
          signup_code: values.signupCode,
          first_name: values.firstName,
          last_name: values.lastName,
          password: values.password,
          confirm_password: values.confirmPassword,
          org_name: values.orgName,
          org_short_name: values.orgShortName,
          region: values.region,
          terms_accepted: values.termsAccepted,
          tracking_code: trackingCode,
        }
        const response = await api.post('/create-account', requestBody);
        if(response.status === 201) {
          if (config.useGlobalIDP  === 'true') {
            await login(values.password);
          } else {
            navigate(window.location.pathname, { replace: true });
            nextStepCallback(4, values.email.toString(), "", "success");
          }
        } else if(response.status === 200) {
          navigate(window.location.pathname, { replace: true });
          nextStepCallback(4, values.email.toString(), "", "create_in_saas_failed");
        }
        else {
          setLoading(false);
          helpers.setErrors({ submit: 'An unexpected error occurred. Please try again!' });
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message) {
          let errorMsg = error.response.data.message;
          if (typeof errorMsg === 'object') {
            if (errorMsg.org_short_name) {
              errorMsg = errorMsg.org_short_name
              if (Array.isArray(errorMsg)) {
                errorMsg = errorMsg.join("\n");
              }
              helpers.setErrors({ orgShortName: errorMsg });
            }
            if (errorMsg.first_name) {
              errorMsg = errorMsg.first_name
              if (Array.isArray(errorMsg)) {
                errorMsg = errorMsg.join("\n");
              }
              helpers.setErrors({ firstName: errorMsg });
            }
            if (errorMsg.last_name) {
              errorMsg = errorMsg.last_name
              if (Array.isArray(errorMsg)) {
                errorMsg = errorMsg.join("\n");
              }
              helpers.setErrors({ lastName: errorMsg });
            }
            if (errorMsg.org_name) {
              errorMsg = errorMsg.org_name
              if (Array.isArray(errorMsg)) {
                errorMsg = errorMsg.join("\n");
              }
              helpers.setErrors({ orgName: errorMsg });
            }
          } else {
            helpers.setErrors({ submit: errorMsg });
          }
        }else {
          helpers.setErrors({ submit: 'An unexpected error occurred. Please try again!' });
        }
        return;
      }
    }
  });

  const login = async(password) => {
    if (initializedThroughSSO && provider) {
      const response = await api.post(config.idpApiUrl + '/sso/' + provider)
      if (response.status === 200) {
        window.location.href = response.data.redirect
      }
    } else {
      await api.post(config.idpApiUrl + '/login', { email, password })
      window.location.href = config.idpFrontendUrl
    }
  }

  return (
    <Box
      sx={{
        my: 0,
        py: {
          xs: 4,
          sm: 8
        },
        mx: {
          xs: 2,
          sm: 4
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: {
          xs: 'flex-start',
          sm: 'center'
        }
      }}
    >
      <Stack
        sx={{
          mb: 2
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          fontWeight={700}
          sx={{
            mb: 1
          }}
        >
          Sign up for a free trial
        </Typography>
        <Typography
          variant="body2"
          color="#6C737F"
        >
          Already have an account?
          {" "}
          <Link
            href="https://admin.keystrike.com"
            color="#2970FF"
            sx={{
              textDecoration: 'none',

              ":hover": {
                opacity: .8
              }
            }}
          >
            Sign in
          </Link>
        </Typography>
      </Stack>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Stack>
          <input type="hidden" name="signupCode" value={signupCode} />
          <FormControl fullWidth>
            <TextField
              error={!!(formik.touched.email && formik.errors.email)}
              margin="normal"
              required
              helperText={formik.touched.email && formik.errors.email}
              id="email"
              label="Email Address"
              name="email"
              type="email"
              data-testid="emailInput"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              disabled
              variant="standard"
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              error={!!(formik.touched.firstName && formik.errors.firstName)}
              margin="normal"
              required
              helperText={formik.touched.firstName && formik.errors.firstName}
              id="firstName"
              label="First Name"
              name="firstName"
              type="text"
              data-testid="firstNameInput"
              autoFocus
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.firstName}          
              inputProps={{ maxLength: 64 }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              error={!!(formik.touched.lastName && formik.errors.lastName)}
              margin="normal"
              required
              helperText={formik.touched.lastName && formik.errors.lastName}
              id="lastName"
              label="Last Name"
              name="lastName"
              type="text"
              data-testid="lastNameInput"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.lastName}          
              inputProps={{ maxLength: 32 }}
            />
          </FormControl>
          {!initializedThroughSSO && (
            <>
            <FormControl fullWidth>
              <TextField
                error={!!(formik.touched.password && formik.errors.password)}
                margin="normal"
                required
                helperText={formik.touched.password && formik.errors.password}
                id="password"
                label="Password"
                name="password"
                type="password"
                data-testid="passwordInput"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                inputProps={{ maxLength: 256 }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                margin="normal"
                required
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                data-testid="confirmPasswordInput"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                inputProps={{ maxLength: 256 }}
              />
              <FormHelperText>
                {passwordRules}
              </FormHelperText>
            </FormControl>
          </>
          )}
          <FormControl fullWidth>         
            <TextField
              error={!!(formik.touched.orgName && formik.errors.orgName)}
              margin="normal"
              required
              helperText={formik.touched.orgName && formik.errors.orgName}
              id="orgName"
              label="Organization Name"
              name="orgName"
              type="text"
              data-testid="orgNameInput"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.orgName}
              inputProps={{ maxLength: 64 }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              error={!!(formik.touched.orgShortName && formik.errors.orgShortName)}
              margin="normal"
              required
              helperText={formik.touched.orgShortName && formik.errors.orgShortName}
              id="orgShortName"
              label="Your Keystrike URL"
              placeholder="your-organization"
              name="orgShortName"
              type="text"
              data-testid="orgShortNameInput"
              onBlur={formik.handleBlur}
              onChange={forceValidShortName}
              value={formik.values.orgShortName}
              inputProps={{ maxLength: 63 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">.keystrike.io</InputAdornment>,
              }}
            />
            <FormHelperText>
              Used in URLs. Can't be changed.
            </FormHelperText>
          </FormControl>                
          <FormControl fullWidth>
            <TextField
              error={!!(formik.touched.region && formik.errors.region)}
              required
              id="region"
              label="Region"
              name="region"
              select
              margin="normal"
              data-testid="regionInput"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.region}
            >
            {getSupportedRegions().map((region) => (              
              <MenuItem key={region} value={region}>
                {getRegionDisplayName(region)}
              </MenuItem>
            ))}
            </TextField>
            <FormHelperText>
              Where your data will live.
            </FormHelperText>
          </FormControl>
          <FormControlLabel
            required
            control={
              <Checkbox
                id="termsAccepted"
                name="termsAccepted"
                data-testid="termsAcceptedInput"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.termsAccepted}
              />
            }
            componentsProps={{
              typography: {
                style: {
                  color: '#6C737F',
                  fontSize: 14
                }
              }
            }}
            label={
              <>
                I accept and agree to the
                {" "}
                <Link 
                  href="https://keystrike.com/terms"
                  target="_blank"
                  color="#2970FF"
                  sx={{
                    textDecoration: 'none',

                    ":hover": {
                      opacity: .8
                    }
                  }}
                >
                  Terms of Service
                </Link>
              </>
            }
          />
          {formik.errors.submit && (
            <FormHelperText
              data-testid="errorMSG"
              error
            >
              {formik.errors.submit}
            </FormHelperText>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            disableFocusRipple
            sx={{ 
              bgcolor: '#2970FF', 
              color: 'white', 
              fontWeight: 600,
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '12px',
              height: '42px',
              textTransform: 'initial',
              mt: 1,

              ":hover": {
                boxShadow: 'none'
              }
            }}
            endIcon={loading ? <CircularProgress /> : <ArrowRight />}
          >
          Register
          </Button>
        </Stack>
      </Box>
    </Box>
  )}