import config from '../config'

export const getSupportedRegions = () => {
    return config.supportedRegions.split(',').map(region => region.trim())
}

export const getHubspotUTK = () => {
    let hubspotUTK = localStorage.getItem('hubspot_hutk');
    if (!hubspotUTK) {
        const hubspotCookieName = 'hubspotutk';
        const cookies = document.cookie.split(';');
        const hubspotUTKCookie = cookies.find(cookie => cookie.trim().startsWith(`${hubspotCookieName}=`));
        if (hubspotUTKCookie) {
            hubspotUTK = hubspotUTKCookie.split('=')[1];
            localStorage.setItem('hubspot_hutk', hubspotUTK);
        }
    }

    return hubspotUTK;
}

export const clearHubspotUTK = () => {
    localStorage.removeItem('hubspot_hutk');
}

export const loadHubSpotScript = () => {
    if (config.hubspotTrackingEnabled && config.hubspotTrackingEnabled === 'true') {
        const script = document.createElement('script');
        script.src = '//js.hs-scripts.com/23432949.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }    
}

export const getUserRegion = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Determine region based on longitude
            if (position.coords.longitude < -30) {
              resolve('US');
            } else {
              resolve('EU');
            }
          },
          (error) => {
            console.error('Error fetching geolocation:', error);
            // Default to US if there's an error or geolocation is unavailable
            resolve('US');
          }
        );
      } else {
        // Fallback if geolocation is not supported
        console.error('Geolocation not supported by this browser.');
        resolve('US'); // Default to US
      }
    });
  }
  